<template>
  <teleport to="#AppContent">
    <div class="card card-bordered">
      <div class="card-aside-wrap">
        <div
          :class="toggleBy ? 'content-active' : ''"
          class="card-aside-right toggle-slide toggle-slide-right w3-white"
          style="height: 100vh; overflow: auto"
          :style="{ width: width ?? '800px' }"
        >
          <!-- <a
            href="#"
            @click.prevent="Close"
            class="
              btn
              w3-white
              btn-dim btn-outline-light
              mt-3
              mr-3
              mb-2
              w3-display-topright
            "
          >
            <em class="dd-indc w3-text-theme bx bx-x"></em>
            <span class="d-none d-md-inline">{{ cancelText }} </span>
          </a> -->
          <div class="card-inner">
            <slot />
          </div>
        </div>
      </div>
      <div v-if="ToggleBy" class="toggle-overlay"></div>
    </div>
  </teleport>
</template>

<script>
import { toRef } from "vue";
export default {
  props: {
    width: {
      type: String,
    },
    toggleBy: {
      type: Boolean,
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
  },
  emits: ["onsave", "oncancel"],
  setup(props, { emit }) {
    const ToggleBy = toRef(props, "toggleBy");
    const Close = () => {
      emit("oncancel");
    };
    return {
      ToggleBy,
      Close,
    };
  },
};
</script>