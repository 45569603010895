<template>
  <table
    :id="ID"
    class="table border"
    :class="Class"
    style="width: 100% !important"
  >
    <slot />
  </table>
</template>
<script>
import { onBeforeUpdate, onUpdated, onMounted, toRef } from "vue";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
export default {
  components: {},
  props: {
    id: {
      type: String,
    },
    class: {
      type: String,
      default: "table table-bordered w3-white m-0",
    },
    options: {
      type: Object,
    },
  },

  setup(props) {
    const ID = props.id ? props.id : uuidv4();
    const Class = toRef(props, "class");
    let Table = null;
    onMounted(() => {
      Table = Util.BuildDataTable("#" + ID, props.options);
    });
    onBeforeUpdate(() => Table.destroy());
    onUpdated(() => (Table = Util.BuildDataTable("#" + ID, props.options)));
    return {
      ID,
      Class,
    };
  },
};
</script>